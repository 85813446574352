<template>
    <div class="action-confirm-meeting">
        <template v-if="isAgentUser">
            <p class="title">
                {{ $nameOrNick(chat.user) }}님이 감사의 표시로<br />
                1만 밥 포인트를 보냈어요!
            </p>

            <div class="body">
                <p class="point m-b-20">
                    보유 밥 포인트: <span class="f-bold">{{ bobPoint }}</span> P
                </p>

                <button class="btn" @click="onClickRequest">환전 신청</button>
            </div>
        </template>

        <template v-else>
            <p class="title">만남 컨펌 완료!</p>

            <div class="body">
                <p>
                    {{ $nameOrNick(chat.user) }}님의 친구({{ content.name }})님과<br />
                    앞으로도 좋은 만남이 되길 바라요! 😊
                </p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ActionRequestRefund',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        isAgentUser() {
            return this.chat.introduce.user_id === this.$store.getters.me.id
        },
        bobPoint() {
            return this.$store.getters.me.bob_point || 0
        },
    },
    methods: {
        onClickRequest() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value:
                    'https://docs.google.com/forms/d/e/1FAIpQLSfPWtR757QMJu89jtp8BwCXK7KKKjEvz7ohNgoSI2RUZKNqrQ/viewform?usp=sf_link ',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-confirm-meeting {
    .point {
        color: $purple-primary;
    }

    .btn {
        background-color: $purple-primary;
        width: 100%;
        color: white;
    }
}
</style>
